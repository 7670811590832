<template>
  <v-form ref="form" v-model="valid" validation>
    <v-text-field
      v-model="promoCode"
      color="#FFB74D"
      label="Promo Code"
      type="text"
      solo
      prepend-icon="mdi-gift"
      :rules="promoCodeRules"
    />
  </v-form>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'PromoCode',
  data: () => ({
    valid: false,
    promoCode: '',
    promoCodeRules: [v => v.length <= 50 || 'Hе более 50 символов']
  }),
  computed: {
    ...mapState({
      promoCodes: state => state.shared.promoCodes
    })
  },
  watch: {
    promoCode: {
      deep: true,
      handler(item) {
        let result = this.promoCodes.find(promoCode => promoCode.name === item)
        if (result) {
          this.$emit('changePrices', result.discount)
        } else {
          this.$emit('changePrices', 0)
        }
      }
    }
  }
}
</script>

<style scoped></style>
